<template>
  <div>
    <!-- banner -->
    <section id="banner">
      <div class="inner">
        <h1 v-html="$t('classesandcounseling.title')"></h1>
        <div class="textBlock" v-html="$t('classesandcounseling.desc1')"></div>
        <div class="textBlock">
          {{ $t("classesandcounseling.desc2-1") }}
          <a class="contactUs">{{ $t("classesandcounseling.desc2-2") }}</a>
          {{ $t("classesandcounseling.desc2-3") }}
        </div>
      </div>
    </section>

    <section>
      <CourseCard />
    </section>
  </div>
</template>

<script>
import CourseCard from "@/views/header/CourseCard.vue";

export default {
  components: {CourseCard},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
#banner {
  text-align: center;
  background-image: url(../../assets/course-intro1.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 8em 0 6em 0;
  height: 520px;
  padding: 0px;
  filter: contrast(90%);
}

#banner .inner {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 150px 20px 50px;
  height: 520px;
  @media (max-width: 450px) {
    padding: 120px 15px 50px;
  }
}

#banner h1 {
  font-size: 4em;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
  color: #fff;
  line-height: 100%;
}

#banner .textBlock {
  color: #fff;
  font-size: 1.5em;
  // margin-bottom: 1.75em;
}

@media screen and (max-width: 1280px) {
  #banner h1 {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    background-attachment: scroll;
  }
  #banner h1 {
    font-size: 2.25em;
  }

  #banner .textBlock {
    font-size: 1.25em;
  }
}
</style>
